import * as React from "react"
import { graphql, Link } from "gatsby"
import FrontmatterPageContext from "~context/FakePageContext"
import { LinkDataURL } from "~components/data_url"
import { Stack } from "@fastly/consistently"
import { main } from "~vanilla/layout-default.css"
import { mainArea } from "~vanilla/layout-default-full.css"

import LayoutCore from "~templates/layout-core"

import NavGuideData from "~data/nav_guides.yml"
import NavProductData from "~data/nav_products.yml"
import NavFundamentalsData from "~data/nav_fundamentals.yml"

import { FavHead } from "~components/Head/FavHead"

// these came from the /index.md page
let frontmatter = {
  // layout: "default_full",
  section: "none",
  section_safe: "none",
  title: "Sitemap | Fastly Documentation",
  lang: "en",
}

const SitemapPage = ({ data, location }) => {



  let GPrimaryDatas = {}
  let GSecondaryData = data.gsecondaries.nodes
  NavGuideData.forEach((element) => {
    const { crumb: pkey, "secondary-nav": snav = [] } = element

    let thisPrimary = data.gprimaries.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this en/guides page
    p_frontmatter["section_url"] = "/en/guides/"

    let thisPrimaryGuides = data.guides_in_primarys.group.find((ggip) => ggip.fieldValue === pkey)

    let myOrphans = data.gprimary_orphans.group.find((g) => g.fieldValue === pkey)

    GPrimaryDatas[pkey] = {
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      thisPrimaryGuidesNodes: thisPrimaryGuides?.nodes,
      myOrphans: myOrphans?.nodes
    }
  })

  let PPrimaryDatas = {}
  NavProductData.forEach((element) => {
    const { crumb: pkey } = element

    let thisPrimary = data.primaries_p.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this products page
    p_frontmatter["section_url"] = "/products/"

    // let thisPrimaryGuides = data.guides_in_primarys_p.group.find( ggip => ggip.fieldValue === pkey)

    let myOrphans = data.primary_orphans_p.group.find((g) => g.fieldValue === pkey)

    PPrimaryDatas[pkey] = {
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      sec_cards: [],
      myOrphans: myOrphans?.nodes
    }
  })

  let FPrimaryDatas = []
  NavFundamentalsData.forEach((element) => {
    const { crumb: pkey } = element

    let thisPrimary = data.primaries_en_f.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this
    p_frontmatter["section_url"] = "/en/fundamentals/"

    let myOrphans = data.primary_orphans_f.group.find((g) => g.fieldValue === pkey)

    FPrimaryDatas[pkey] = {
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      sec_cards: [],
      myOrphans: myOrphans?.nodes
    }
  })


  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main`}>
            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className="content-content">
                <div>
                  <Stack direction={["horizontal"]} gap={["md"]}>
                    <>Jump to:</>
                    <a href="#_guides">Guides</a>
                    <a href="#_products">Products</a>
                    <a href="#_fundamentals">Fundamentals</a>
                    <a href="#_changes">Changes</a>
                  </Stack>
                </div>

                <h1 id={`_guides`}><Link to="/en/guides/">Support guides</Link></h1>

                {NavGuideData.map((ngd) => {
                  let pdata = GPrimaryDatas[ngd.crumb]
                  let { 'secondary-nav': secnavs } = ngd

                  return (
                    <section>
                      <h2><Link to={pdata.pfrontmatter.section_url}>{pdata.pfrontmatter.section_name}</Link></h2>
                      <p>{pdata.pfrontmatter.description}</p>

                      <ul>
                        {pdata.myOrphans?.map((og) => {
                            return (<li key={og.slug}><Link to={`${pdata.pfrontmatter.section_url}${og.slug.split('/', 2)[1]}`}>{og.frontmatter.header}</Link></li>)
                        })}
                      </ul>

                      {secnavs?.map((secondary) => {
                        const { crumb: scrumb } = secondary
                        const sdata = GSecondaryData?.find((secnode) => secnode.frontmatter.crumb === scrumb).frontmatter
                        // let sdata = undefined
                        const sguides = pdata?.thisPrimaryGuidesNodes?.filter((gip) => gip.frontmatter.secondary_nav = scrumb)

                        return <>
                          <h3>{sdata.name}</h3>
                          <p>{sdata.description}</p>

                          <ul>{sguides.map((sg) => {
                            return (<li key={sg.slug}><Link to={`${pdata.pfrontmatter.section_url}${sg.slug.split('/', 2)[1]}`}>{sg.frontmatter.header}</Link></li>)
                          })}</ul>
                        </>
                      })}
                    </section>
                  )
                })}

                <hr />

                <h1 id={`_products`}><Link to="/products/">Products</Link></h1>

                {NavProductData.map((npd) => {
                  let pdata = PPrimaryDatas[npd.crumb]

                  return <section>
                    <h2><Link to={pdata.pfrontmatter.section_url}>{pdata.pfrontmatter.section_name}</Link></h2>
                      <p>{pdata.pfrontmatter.description}</p>

                    <ul>
                      {pdata.myOrphans?.map((og) => {
                          return (<li key={og.slug}><Link to={`${pdata.pfrontmatter.section_url}${og.slug.split('/', 2)[1]}`}>{og.frontmatter.header}</Link></li>)
                      })}
                    </ul>
                  </section>
                })}

                <hr />

                <h1 id={`_fundamentals`}><Link to="/en/fundamentals/">Fundamentals</Link></h1>

                {NavFundamentalsData.map((nfd) => {
                  let pdata = FPrimaryDatas[nfd.crumb]
                  let { 'secondary-nav': secnavs } = nfd

                  return (
                    <section>
                      <h2><Link to={pdata.pfrontmatter.section_url}>{pdata.pfrontmatter.section_name}</Link></h2>
                      <p>{pdata.pfrontmatter.description}</p>

                      <ul>
                        {pdata.myOrphans?.map((og) => {
                            return (<li key={og.slug}><Link to={`${pdata.pfrontmatter.section_url}${og.slug.split('/', 2)[1]}`}>{og.frontmatter.header}</Link></li>)
                        })}
                      </ul>
                    </section>
                  )
                })}

                <hr />

                <h1 id={`_changes`}><Link to="/changes/">Changes</Link></h1>

                <ul>
                  <li><Link to="/changes/">Recent Updates</Link></li>
                  <li><Link to="/changes/archive">Older Updates</Link></li>
                  <li><Link to="/changes/significant/">Significant Updates</Link></li>
                  <li><Link to="/changes/significant/archive">Older Significant Updates</Link></li>
                  <li><LinkDataURL to="snapshots-archive">Content Snapshots</LinkDataURL></li>
                </ul>

              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default SitemapPage

export const Head = (props) => {
  return <>
    <title>{frontmatter.title}</title>
    <FavHead />
  </>
}

export const sitemapQuery = graphql`
  query {
    guides_in_primarys: allMdx(
      filter: {
        slug: { regex: "/^_en_guides//" }
        frontmatter: { unlisted: { ne: true }, orphan: { ne: true } }
      }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
          }
        }
      }
    }
    gprimaries: allMdx(filter: { fileAbsolutePath: { regex: "/_en_guides_primary/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    gsecondaries: allMdx(filter: { fileAbsolutePath: { regex: "/_en_guides_secondary/" } }) {
      nodes {
        fileAbsolutePath
        slug
        # body
        frontmatter {
          name
          crumb
          description
        }
      }
    }
    gprimary_orphans: allMdx(
      filter: {
        frontmatter: { orphan: { eq: true }, unlisted: { ne: true } }
        fileAbsolutePath: { regex: "/_en_guides//" }
      }
      sort: { fields: fileAbsolutePath }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          excerpt
          frontmatter {
            primary_nav
            # secondary_nav
            header
            # unlisted
            # orphan
          }
        }
      }
    }

    guides_in_primarys_p: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/_products/" }
        frontmatter: { unlisted: { ne: true }, orphan: { ne: true } }
      }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
          }
        }
      }
    }
    primaries_p: allMdx(filter: { fileAbsolutePath: { regex: "/_products_primary/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    primary_orphans_p: allMdx(
      filter: {
        frontmatter: {
          # orphan: {eq: true}
          unlisted: { ne: true }
        }
        fileAbsolutePath: { regex: "/_products//" }
      }
      sort: { fields: fileAbsolutePath }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          excerpt
          frontmatter {
            primary_nav
            # secondary_nav
            header
            # unlisted
            # orphan
          }
        }
      }
    }

    fundamentals_in_primarys: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/_en_fundamentals//" }
        frontmatter: { unlisted: { ne: true }, published: { ne: false } }
      }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
          }
        }
      }
    }
    primaries_en_f: allMdx(filter: { fileAbsolutePath: { regex: "/_en_fundamentals_primary/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    primary_orphans_f: allMdx(
      filter: {
        frontmatter: {
          # orphan: {eq: true}
          unlisted: { ne: true }
        }
        fileAbsolutePath: { regex: "/_en_fundamentals//" }
      }
      sort: { fields: fileAbsolutePath }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          excerpt
          frontmatter {
            primary_nav
            # secondary_nav
            header
            # unlisted
            # orphan
          }
        }
      }
    }
  }
`
